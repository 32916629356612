import { render, staticRenderFns } from "./index.vue?vue&type=template&id=578c3bd9&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=578c3bd9&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "578c3bd9",
  null
  
)

/* custom blocks */
import block0 from "./en.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvar%2Fbsat%2Fsrc%2Fcomponents%2Fnew%2FAbout%2Findex.vue"
if (typeof block0 === 'function') block0(component)
import block1 from "./cz.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fvar%2Fbsat%2Fsrc%2Fcomponents%2Fnew%2FAbout%2Findex.vue"
if (typeof block1 === 'function') block1(component)
import block2 from "./hu.json?vue&type=custom&index=2&blockType=i18n&issuerPath=%2Fvar%2Fbsat%2Fsrc%2Fcomponents%2Fnew%2FAbout%2Findex.vue"
if (typeof block2 === 'function') block2(component)
import block3 from "./pl.json?vue&type=custom&index=3&blockType=i18n&issuerPath=%2Fvar%2Fbsat%2Fsrc%2Fcomponents%2Fnew%2FAbout%2Findex.vue"
if (typeof block3 === 'function') block3(component)
import block4 from "./sk.json?vue&type=custom&index=4&blockType=i18n&issuerPath=%2Fvar%2Fbsat%2Fsrc%2Fcomponents%2Fnew%2FAbout%2Findex.vue"
if (typeof block4 === 'function') block4(component)
import block5 from "./uk.json?vue&type=custom&index=5&blockType=i18n&issuerPath=%2Fvar%2Fbsat%2Fsrc%2Fcomponents%2Fnew%2FAbout%2Findex.vue"
if (typeof block5 === 'function') block5(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VHover } from 'vuetify/lib/components/VHover';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VHover,VImg})
