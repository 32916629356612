<template>
  <div class="about">
    <VueGallery
      id="gallery-about"
      :images="images"
      :index="index"
      @close="index = null"
    ></VueGallery>
    <div class="bsat__title">{{ $t("title") }}</div>
    <div class="bsat__paragraph">
      <i18n path="paragraph.text" tag="div" class="bsat__paragraph">
        <template #link1>
          <a @click="goToTab(1)">{{ $t("paragraph.link1") }}</a>
        </template>

        <template #link2>
          <a @click="goToTab(2)">{{ $t("paragraph.link2") }}</a>
        </template>

        <template #link3>
          <a @click="goToTab(3)">{{ $t("paragraph.link3") }}</a>
        </template>

        <template #link4>
          <a @click="goToTab(4)">{{ $t("paragraph.link4") }}</a>
        </template>
      </i18n>
    </div>
    <v-hover v-slot="{ hover }">
      <div
        style="position: relative"
        :style="hover ? 'opacity: 0.7; transition: opacity 0.5s;' : ''"
        class="mt-15 photo"
        @click="index = 0"
      >
        <v-img contain height="550px" src="@/assets/icons/New.png"></v-img>
        <transition name="fade">
          <div v-if="hover" class="hover">{{ $t("clickToEnlarge") }}</div>
        </transition>
      </div>
    </v-hover>
  </div>
</template>

<i18n src="./en.json"></i18n>
<i18n src="./cz.json"></i18n>
<i18n src="./hu.json"></i18n>
<i18n src="./pl.json"></i18n>
<i18n src="./sk.json"></i18n>
<i18n src="./uk.json"></i18n>

<script>
import { EventBus } from "@/utils/EventBus";
import VueGallery from "vue-gallery";

export default {
  components: {
    VueGallery
  },
  data() {
    return {
      index: null,
      images: [require("@/assets/icons/New.png")]
    };
  },
  methods: {
    goToTab(tab) {
      EventBus.$emit("goToTab", tab);
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/main";

.about {
  margin: 20px 0px;
}
.slide-content {
  background-color: #ffffff !important;
}
.blueimp-gallery {
  padding: 40px;
}

.hover {
  position: absolute;
  text-align: center;
  color: #fff;
  top: 50%;
  left: calc(50% - 80px);
  opacity: 1;
  width: 200px;
  height: auto;
  padding: 10px 20px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.8);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
